<template>
  <div class="error-page">
    <img src="/images/404.png" alt="error" />
  </div>
</template>

<style scoped>
.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: inherit
}
</style>